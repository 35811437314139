import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { app as teamsjs } from "@microsoft/teams-js"
import { authentication as teamsjsauth } from "@microsoft/teams-js"
import { isMobile } from 'react-device-detect'
import axios from "axios"
import dayjs from "dayjs"
import Button from 'react-bootstrap/Button';

import ContactSearchResult from "./ContactSearchResult"
import ContactCall from "./ContactCall"
import ContactSpeedDial from "./ContactSpeedDial"
import SectionHeader from "./SectionHeader"
import SpeedDialAdd from "./SpeedDialAdd"
import SearchField from "./SearchField"
import HistoryFilter from "./HistoryFilter"


import { LookupApiSearchName } from "../ExternalApi/LookupApi"
import { PhoneApiSubscribe, PhoneApiMakeCall } from "../ExternalApi/PhoneApi"
import { PushApiGetCallList, PushApiGetUserData, PushApiSetUserData, PushApiAddCall, PushApiDeleteCall, PushApiSignOut } from "../ExternalApi/PushApi"
import { TeamsISSApiSubscribe, TeamsISSApiLogout } from "../ExternalApi/TeamsISSApi"
import { OAuthApiStartPolling } from "../ExternalApi/OAuthApi"
import { Log } from "../ExternalApi/LogApi"
import { DarkBackground } from "./GeneralFunctions"
import { Translate } from "./Translator"

import SignOutLightBgPng from '../Assets/signout_lightbg.png'
import SignOutDarkBgPng from '../Assets/signout_darkbg.png'
import WebConfiguratorPng from '../Assets/webconfigurator.png'

import "../css/Main.css";

const Main = (props) => {

  const [mRepaint, SetRepaint] = useState(false)

  const [mVisualMode, SetVisualMode] = useState("FullMode")
  const [mMobileModeWindow, SetMobileModeWindow] = useState(localStorage.getItem("MobileModeWindow") || "Search")

  const [mCurrentSearchError, SetCurrentSearchError] = useState("")
  const [mCurrentSearchText, SetCurrentSearchText] = useState("")
  const [mCurrentSearchResults, SetCurrentSearchResults] = useState([])
  const [mCurrentSearchResultsMore, SetCurrentSearchResultsMore] = useState(false)

  const [mCallHistoryId, SetCallHistoryId] = useState(0)
  const [mLastSearches, SetLastSearches] = useState([])
  const [mCallHistory, SetCallHistory] = useState([])
  const [mSpeedDials, SetSpeedDials] = useState([])

  const [mSearchFilter, SetSearchFilter] = useState("")
  const [mCallHistoryFilter, SetCallHistoryFilter] = useState("")
  const [mCallHistoryFolded, SetCallHistoryFolded] = useState(false)
  
  const [mSpeedDialFolded, SetSpeedDialFolded] = useState(false)
   
  const CallHistory = mCallHistory.filter(call => (((call.Incoming ? "Incoming" : "Outgoing") === mCallHistoryFilter) || (mCallHistoryFilter === "")))

  const [mLastError, SetLastError] = useState("")

  const mFontColor = props.Teams.theme.colorNeutralForeground1
  const mFontFamily = props.Teams.theme.fontFamilyBase
  const mBackgroundColor = props.Teams.theme.colorNeutralBackground1
  const mBorderColor = props.Teams.theme.colorNeutralStroke3

  useEffect(() => {

    const Subscribe = async () => {

      try 
      {
        var subscribeResult = await PhoneApiSubscribe(props.SecurityToken)
        Log("PhoneApiSubscribe response: " + JSON.stringify(subscribeResult))

        if (subscribeResult.data)
        {
            if (subscribeResult.status === 200)
            {
              console.log("Phone subscription OK")
            }
            else
            {
              let error = "";
              try { error = subscribeResult.data.Exception; } catch {}
              SetLastError(Translate("Unable to acquire a subscription") + (error === "" ? "" : (": " + error)))
            }
        }
        else
        {
          SetLastError(Translate("Unable to acquire a subscription"))            
        }
      } 
      catch (ex) 
      {    
          let error = ex.message ? ex.message : JSON.stringify(ex)
          try 
          { 
            const innererror = ex.response.data.Exception
            if (innererror) { error = innererror }
          } 
          catch {}
          SetLastError(Translate("Unable to acquire a subscription") + (error === "" ? "" : (": " + error)))
      }  
    }

    const handleResize = () => {
      SetVisualMode(GetVisualMode())
    }

    localStorage.setItem("extralog2","GetUserData")
    GetUserData()

    localStorage.setItem("extralog2","GetCallHistory")
    GetCallHistory()

    if (props.PhoneType === "CloudCTI")
    {
      //At the moment, the phone api does not support teams. This could possibly change later if subscribing is done through the teams api
      Subscribe()
    }
    else if (props.PhoneType === "Teams")
    {
      localStorage.setItem("extralog2","PhoneTypeTeams")
      try
      {
        TeamsISSApiSubscribe(props.MicrosoftToken, props.Teams.context.user.tenant.id, props.Teams.context.user.userPrincipalName).then((result) => { 
          if ((result !== 200) && (result !== 201)) { SetLastError("Failed to check Teams event subscription") } 
          else if (result === 201)
          {
            const state = RandomState()
            const OAuthWindow = window.open(
              "https://teamsservercloudcti-app.azurewebsites.net/Auth/ForceInteractive/" + props.MicrosoftToken + "?tenantid=" + props.Teams.context.user.tenant.id + "&state=" + state, 
              "_blank", 
              'popup=yes,titlebar=0,left=' + ((screen.availWidth - 750)/2)  + ',top=' + ((screen.availHeight - 750)/2)  + ',width=750,height=750')
            OAuthApiStartPolling(state, OAuthPollResult, OAuthWindow)
            SetLastError(Translate("Teams phone API authorization pending"))
          }})    
      }
      catch
      {
        SetLastError(Translate("Teams phone API authorization failed"))
      }
      localStorage.setItem("extralog2","PhoneTypeTeamsEnd")
    }

    setInterval(() => GetCallHistory(), 10000) //Requests the callhistory every 10 seconds. Automatically repeats

    SetVisualMode(GetVisualMode())
  
    localStorage.setItem("extralog2","addEventListener")
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const GetVisualMode = () =>
  {
    //return isMobile && (window.innerWidth < 740) ? "MobileMode" :
    return (window.innerWidth < 740) ? "MobileMode" :
      (window.innerWidth < 1000 ? "CompactMode" :
      (window.innerWidth < 1200 ? "SemiFullMode" : "FullMode"))
  }

  const OAuthPollResult = (aResult, aValue, aOAuthWindow) =>
    {
        try 
        { 
          if (aOAuthWindow !== null)
          {
            aOAuthWindow.close() 
          }
        } 
        catch {}

        if (aResult)
        {
          if (aValue.Code == "200")
          {
            SetLastError("")
          }
          else
          {
            SetLastError(Translate("Teams phone API authorization failed"))
          }
        }
        else
        {
          SetLastError(Translate("Teams phone API authorization failed"))        
        }
    }

  const GetCallHistory = async () => 
  {
    const callList = await PushApiGetCallList(props.SecurityToken, mCallHistoryId)
    if (callList)
    {
      if (callList.Changed)
      {
        SetCallHistory(callList.NewCalls)
        SetCallHistoryId(callList.Id)
      }
    } 
    else
    {
      SetLastError(Translate("Unable to acquire calllist"))           
    }
  }

  const GetUserData = async () => 
  {
    const userData = await PushApiGetUserData(props.SecurityToken)
    if (userData)
    {
      SetLastSearches(userData.SearchResults)
      SetSpeedDials(userData.SpeedDials)
    } 
    else
    {
      SetLastError(Translate("Unable to acquire userdata"))           
    }
  }

  const MobileModeWindowChange = (aWindow) =>
  {
    SetMobileModeWindow(aWindow)
    localStorage.setItem("MobileModeWindow",aWindow)
  }

  const NumberInSpeedDial = (aNumber) =>
  {
    return mSpeedDials.filter(speedDial => (speedDial.Number === aNumber)).length > 0
  }

  const SearchFieldChanged = () =>
  {
    SetCurrentSearchError("")
  }

  const SearchName = async (value) => 
  {
      try 
      {
          SetCurrentSearchResults(null)
          SetCurrentSearchResultsMore(false)
          SetCurrentSearchText(value)

          if ((value.length > 0) && (value.length < 3))
          {
            SetCurrentSearchError(Translate("Search field must contain at least 3 characters"))
            return
          }
          else if (value.length > 2)
          {
            const searchResult = await LookupApiSearchName(props.SecurityToken, value)

            if (searchResult)
            {
              SetCurrentSearchResults(searchResult.Matches)
              SetCurrentSearchResultsMore(searchResult.MoreMatches)
              SetCurrentSearchError("")
            }
            else
            {
              SetCurrentSearchResults([])
              SetCurrentSearchResultsMore(false)
              SetCurrentSearchError(Translate("An error has occurred while searching"))           
            }
          }
      } 
      catch (ex) 
      {    
          SetCurrentSearchResults([])

          let error = ""
          try { error = ex.response.data.Exception; } catch {}
          if (!error) { try { error = ex.response.data } catch {} }
          SetCurrentSearchError(error ? error : Translate("An error has occurred while searching"))
      }
  }

  const AddToSpeedDial = async (aNumber, aName, aApplication, aScriptName, aScreenpopUrl) => 
  {
    try 
    {  
        const SpeedDials = [...mSpeedDials,...[{ Number: aNumber, Name:aName, Application:aApplication, ScriptName:aScriptName, ScreenpopUrl:aScreenpopUrl}]]
        PushApiSetUserData(props.SecurityToken, mLastSearches, SpeedDials)
        GetUserData()
    } 
    catch (ex) 
    {    
        let error = ex;
        console.log("AddToSpeedDial failed: " + error);
    }
  }

  const RemoveFromSpeedDialByIndex = async (aIndex) => 
  {
    try 
    {  
        const SpeedDials = mSpeedDials.filter((item,index) => index !== aIndex)
        PushApiSetUserData(props.SecurityToken, mLastSearches, SpeedDials)
        GetUserData()
    } 
    catch (ex) 
    {    
        let error = ex;
        Log("RemoveFromSpeedDial failed: " + error);
    }
  }  

  const RemoveFromSpeedDialByNumber = async (aNumber) => 
    {
      try 
      {  
          const SpeedDials = mSpeedDials.filter((item,index) => item.Number !== aNumber)
          PushApiSetUserData(props.SecurityToken, mLastSearches, SpeedDials)
          GetUserData()
      } 
      catch (ex) 
      {    
          let error = ex;
          Log("RemoveFromSpeedDial failed: " + error);
      }
    } 

  const DeleteFromCallList = async (aId) => 
  {
    try 
    {  
        await PushApiDeleteCall(props.SecurityToken, aId)
        GetCallHistory()
    } 
    catch (ex) 
    {    
        let error = ex;
        Log("DeleteFromCallList failed: " + error);
    }    
  }

  const MakeCallBase = async (aNumber, aName, aApplication, aScripts) => 
  {    
      if (props.PhoneType === "CloudCTI")
      {
        await PhoneApiMakeCall(props.SecurityToken, aNumber)
      }
      else if (props.PhoneType === "Teams")
      {
        try { teamsjs.openLink("https://teams.microsoft.com/l/call/0/0?users=4:" + aNumber.replace("+","%2B")) } catch { }
      }

      await PushApiAddCall(props.SecurityToken, aNumber, aName, aApplication, false, aScripts)
      GetCallHistory()
  }  

  const MakeCall = async (aNumber, aName, aApplication, aScripts) => 
  {
    try 
    {  
        MakeCallBase(aNumber, aName, aApplication, aScripts)
    } 
    catch (ex) 
    {    
        let error = ex;
        console.log("MakeCall failed: " + error);
    }
  }

  const MakeCallAndAddToLastSearches = async (aNumber, aName, aApplication, aScripts) => 
  {
    try 
    {  
        MakeCallBase(aNumber, aName, aApplication, aScripts)
        
        let ExistingItems = mLastSearches.filter((item,index) => (item.Number !== aNumber)) //First filter out the item with the same number
        ExistingItems = ExistingItems.filter((item,index) => (index < 9)) //Then filter out excess items
        const LastSearches = [{ Number: aNumber, Name:aName, Application:aApplication, Scripts:aScripts},...ExistingItems]
        PushApiSetUserData(props.SecurityToken, LastSearches, mSpeedDials)
        GetUserData()
        SetRepaint(!mRepaint)

        //Remove search
        SetSearchFilter("")
        SetCurrentSearchText("")
        SetCurrentSearchResults([])
    } 
    catch (ex) 
    {    
        let error = ex;
        console.log("MakeCall failed: " + error);
    }
  }

  function getClientSideToken() {
    return new Promise((resolve, reject) => {        
        teamsjsauth.getAuthToken().then((result) => {
            resolve(result);
        }).catch((error) => {
            reject("Error getting microsoft token: " + error)
        });
    });
  }

  const RandomState = () => 
    {
        let Result = "client2020teams"

        var characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        for ( var i = 0; i < 27; i++ ) 
        {
            Result += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        
        return Result
    }

  async function TeamsISSApiLogoutIfPresent(aAccessToken)
  {
    if (props.PhoneType === "Teams")
    {
      await TeamsISSApiLogout(aAccessToken, props.Teams.context.user.tenant.id)
    }
  }

  function getServerSideTokenAndSignOut(clientSideToken) {
    return new Promise((resolve, reject) => {
        teamsjs.getContext().then((context) => {
          axios.post("https://teamsservercloudcti-app.azurewebsites.net/getSsoServerSideToken",  
          {
              tid: context.user.tenant.id,
              token: clientSideToken,
              //isbeta: (props.Beta ? "true" : "false")
          },
          {
              headers: {
                  ContentType: "application/json",
                  Accept: "application/json"
              }
          })
          .then((response) => {
            TeamsISSApiLogoutIfPresent(response.data.accessToken).then(
              PushApiSignOut(response.data.accessToken).then(() => {
                window.location.reload()
            }))
          })
          .catch((error) => {
            SetLastError(error)
          })
        });
    });
  }

  const SignOut = async () => {
    teamsjs.initialize().then(() => {
      getClientSideToken()
          .then((clientSideToken) => {
            getServerSideTokenAndSignOut(clientSideToken)
          })
          .catch((error) => {
            SetLastError(error)
          })
        })
  }

  const OpenWebConfigurator = () => {
    window.open("https://webconfigurator.cloudcti.nl/?handover_token=" + props.SecurityToken)
  }

  const MakeOsLocaleInfoShortDate = (aDateTime) => {

    try {
      const NowDate = new Date()
      const ThenDate = new Date(aDateTime + " UTC")
      if ((NowDate.getFullYear() === ThenDate.getFullYear())
        && (NowDate.getMonth() === ThenDate.getMonth())
        && (NowDate.getDate() === ThenDate.getDate()))
      {
        return ThenDate.toLocaleTimeString(props.Teams.context.app.locale, {})
      }
      else
      {
        return ThenDate.toLocaleDateString(props.Teams.context.app.locale, {})
      }

    } catch (error) {
      return new Date(aDateTime).toLocaleDateString();
    }
  } 

  let WebConfiguratorLink = <span/>
  let WebConfiguratorLink2 = <span/>
  if (props.UserIsAdmin)
  {
    WebConfiguratorLink = <span className="openwebconfigurator" onClick={() => OpenWebConfigurator()}><img alt="" src={WebConfiguratorPng} className="action up1px" width="17" height="17"/> {Translate("Configure your CRM")}</span>
    WebConfiguratorLink2 = <br/>
  }

  //===>
  //These functions are all about return a part of the html 
  //Split up to have as little duplicate code as possible for different modes

  const HtmlHistoryHeader = () => {
    return <table width="100%">
      <tbody>
        <tr>
          <td width="130" align="left" className="sectionheader">
            <SectionHeader Folded={mCallHistoryFolded} Text={Translate("History")} TeamsTheme={props.Teams.theme} OnClick={e => SetCallHistoryFolded(!mCallHistoryFolded)}/>
          </td>
          <td width="*" align="right">
            <HistoryFilter Caption={Translate("All")} Value="" Selected={mCallHistoryFilter === ""} OnSetCallHistoryFilter={() => SetCallHistoryFilter("")}/>
            &nbsp;&nbsp;&nbsp;
            <HistoryFilter Caption={Translate("Outgoing")} Value="Outgoing" Selected={mCallHistoryFilter === "Outgoing"} OnSetCallHistoryFilter={() => SetCallHistoryFilter("Outgoing")}/>
            &nbsp;&nbsp;&nbsp;
            <HistoryFilter Caption={Translate("Incoming")} Value="Incoming" Selected={mCallHistoryFilter === "Incoming"} OnSetCallHistoryFilter={() => SetCallHistoryFilter("Incoming")}/>
          </td>
        </tr>                
      </tbody>
    </table>
  }

  const HtmlSpeedDialHeader = () => {
    return <table width="100%">
      <tbody>
        <tr>
          <td width="*" align="left" className="sectionheader">
            <SectionHeader Folded={mSpeedDialFolded} Text={Translate("Speed dial")} TeamsTheme={props.Teams.theme} OnClick={e => SetSpeedDialFolded(!mSpeedDialFolded)}/>                    
          </td>
        </tr>
      </tbody>
    </table>
  }

  const HtmlSearchResultsRow = () => {
    return <tr height="*">
      <td align="left" valign="top">

        {mCurrentSearchError ? <div className="contactlistitem normalcell">{mCurrentSearchError}</div> :
          (mCurrentSearchText ? 
            mCurrentSearchResults === null ?
            <div className="contactlistitem normalcell">{Translate("Searching")}...</div> :

            mCurrentSearchResults.length ? (
                mCurrentSearchResults.map((currentSearch,index) => (
                  <div className="contactlistitem"><ContactSearchResult key={index} Number={currentSearch.Number} Name={currentSearch.Name} Organization={currentSearch.Organization} Application={currentSearch.Application} Scripts={currentSearch.Scripts} TenantGuid={props.TenantGuid} TeamsTheme={props.Teams.theme} MakeCallEvent={(number, name, application, scripts) => MakeCallAndAddToLastSearches(number, name, application, scripts)}/></div>
                ))
            ) : 
            <div className="contactlistitem normalcell">{Translate("No results found")}</div>
          :
          mLastSearches.map((lastSearch,index) => (
            <div className="contactlistitem"><ContactSearchResult key={index} Number={lastSearch.Number} Name={lastSearch.Name} Organization={lastSearch.Organization} Application={lastSearch.Application} Scripts={lastSearch.Scripts} TenantGuid={props.TenantGuid} TeamsTheme={props.Teams.theme} MakeCallEvent={(number, name, application, scripts) => MakeCall(number, name, application, scripts)}/></div>
          )))              
        }

        {mCurrentSearchResultsMore ? <div align="center" className="smalltext normalcell"><br/>{Translate("More results found. Refine your search")}</div> : <span/>}
             
      </td>
    </tr>
  }

  const HtmlSignoutAndLinksRow = () => {
    return <tr height="*">
      <td align="left" valign="bottom">
        <br/>
        {WebConfiguratorLink}
        {WebConfiguratorLink2}
        <span className="signout" onClick={() => SignOut()}><img alt="" src={DarkBackground(mBackgroundColor) ? SignOutDarkBgPng : SignOutLightBgPng} className="action up1px" width="17" height="17"/> {Translate("Sign out")}</span><br/>
        &nbsp;
      </td>
    </tr>
  }

  const ReturnFullMode = () => {
    return <table className="main">        
      <tbody>
      <tr height="24">
          <td width="10"></td>
          <td width="366" className="padded10 nobottompadding" valign="top">
            <SearchField OnChanged={() => SearchFieldChanged()} OnSearchName={(searchfield) => SearchName(searchfield)} />
          </td>
          <td width="*" valign="top" className="padded10 mainborderbottom mainborderleft mainborderright">
            {HtmlHistoryHeader()}
          </td>
          <td width="339" valign="top" className="padded10 mainborderbottom mainborderleft mainborderright">
            {HtmlSpeedDialHeader()}
          </td>
      </tr>
      <tr height="*">
          <td/>
          <td className="padded10 contactlistitem" valign="top">

            <table height="100%" width="100%" cellPadding="0" cellSpacing="0">
              <tbody>
                {HtmlSearchResultsRow()}
                {HtmlSignoutAndLinksRow()}
              </tbody>
            </table>
          </td>
          <td valign="top" className="padded10 mainborderleft mainborderright">

            <div className="historymain" style={{visibility: (mCallHistoryFolded ? "hidden" : "visible")}}>
              {CallHistory.map((call,index) => (
                <div className="contactlistitem"><ContactCall key={index} Id={call.Id} Number={call.Number} Name={call.Name} Application={call.Application} Scripts={call.Scripts} Direction={call.Incoming ? "Incoming" : "Outgoing"} Time={MakeOsLocaleInfoShortDate(call.DateTime)} Duration={call.Duration} AlreadySpeedDial={NumberInSpeedDial(call.Number)} TeamsTheme={props.Teams.theme} VisualMode={mVisualMode} OnMakeCall={(number, name, application, scriptName, screenpopUrl) => MakeCall(number, name, application, scriptName, screenpopUrl)} OnAddToSpeedDial={(number, name, application, scriptName, screenpopUrl) => AddToSpeedDial(number, name, application, scriptName, screenpopUrl)} OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDialByNumber(number)} OnDeleteFromCallList={(id) => DeleteFromCallList(id)}/></div>
              ))} 
            </div>

          </td>
          <td className="padded10" valign="top">
            
            <div style={{visibility: (mSpeedDialFolded ? "hidden" : "visible")}}>
              {mSpeedDials.map((speeddial,index) => (
                <div className="contactlistitem"><ContactSpeedDial key={index} Number={speeddial.Number} Name={speeddial.Name} Application={speeddial.Application} ScriptName={speeddial.ScriptName} ScreenpopUrl={speeddial.ScreenpopUrl} MakeCallEvent={(number, name, application, scriptName, screenpopUrl) => MakeCall(number, name, application, scriptName, screenpopUrl)} RemoveFromSpeedDialEvent={() => RemoveFromSpeedDialByIndex(index)}/></div>
              ))} 
            </div>

          </td>
      </tr>
      <tr height="22">
          <td colSpan="4" className="bottomerror" align="right" valign="center">{mLastError}</td>
      </tr>
      </tbody>
      </table>   
  }

  const ReturnSemiFullMode = () => {

    return <table className="main">        
      <tbody>
      <tr height="24">
          <td width="10"></td>
          <td width="315" className="padded10 nobottompadding" valign="top">
            <SearchField OnChanged={() => SearchFieldChanged()} OnSearchName={(searchfield) => SearchName(searchfield)} />
          </td>
          <td width="*" valign="top" className="padded10 mainborderbottom mainborderleft mainborderright">
            {HtmlHistoryHeader()}
          </td>
          <td width="339" valign="top" className="padded10 mainborderbottom mainborderleft mainborderright">
            {HtmlSpeedDialHeader()}
          </td>
      </tr>
      <tr height="*">
          <td/>
          <td className="padded10 contactlistitem" valign="top">

            <table height="100%" width="100%" cellPadding="0" cellSpacing="0">
              <tbody>
                {HtmlSearchResultsRow()}
                {HtmlSignoutAndLinksRow()}
              </tbody>
            </table>

          </td>
          <td valign="top" className="padded10 mainborderleft mainborderright">

            <div className="historymain" style={{visibility: (mCallHistoryFolded ? "hidden" : "visible")}}>
              {CallHistory.map((call,index) => (
                <div className="contactlistitem"><ContactCall key={index} Id={call.Id} Number={call.Number} Name={call.Name} Application={call.Application} Scripts={call.Scripts} Direction={call.Incoming ? "Incoming" : "Outgoing"} Time={MakeOsLocaleInfoShortDate(call.DateTime)} Duration={call.Duration} AlreadySpeedDial={NumberInSpeedDial(call.Number)} TeamsTheme={props.Teams.theme} VisualMode={mVisualMode} OnMakeCall={(number, name, application, scriptName, screenpopUrl) => MakeCall(number, name, application, scriptName, screenpopUrl)} OnAddToSpeedDial={(number, name, application, scriptName, screenpopUrl) => AddToSpeedDial(number, name, application, scriptName, screenpopUrl)} OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDialByNumber(number)} OnDeleteFromCallList={(id) => DeleteFromCallList(id)}/></div>
              ))} 
            </div>

          </td>
          <td className="padded10" valign="top">
            
            <div style={{visibility: (mSpeedDialFolded ? "hidden" : "visible")}}>
              {mSpeedDials.map((speeddial,index) => (
                <div className="contactlistitem"><ContactSpeedDial key={index} Number={speeddial.Number} Name={speeddial.Name} Application={speeddial.Application} ScriptName={speeddial.ScriptName} ScreenpopUrl={speeddial.ScreenpopUrl} MakeCallEvent={(number, name, application, scriptName, screenpopUrl) => MakeCall(number, name, application, scriptName, screenpopUrl)} RemoveFromSpeedDialEvent={() => RemoveFromSpeedDialByIndex(index)}/></div>
              ))} 
            </div>

          </td>
      </tr>
      <tr height="22">
          <td colSpan="4" className="bottomerror" align="right" valign="center">{mLastError}</td>
      </tr>
      </tbody>
      </table>   
  }

  const ReturnCompactMode = () => {
    
    return <table className="main">        
      <tbody>
      <tr height="24">
          <td width="10"></td>
          <td width="315" className="padded10 nobottompadding" valign="top">
            <SearchField OnChanged={() => SearchFieldChanged()} OnSearchName={(searchfield) => SearchName(searchfield)} />
          </td>
          <td width="*" valign="top" className="padded10 mainborderbottom mainborderleft mainborderright">
            {HtmlSpeedDialHeader()}
          </td>
      </tr>
      <tr height="*">
          <td width="10"></td>
          <td width="315" className="padded10 contactlistitem" valign="top">

              <table height="100%" width="100%" cellPadding="0" cellSpacing="0">
                <tbody>
                  {HtmlSearchResultsRow()}
                  {HtmlSignoutAndLinksRow()}
                </tbody>
              </table>

          </td>
          <td width="*" valign="top" className="mainborderleft mainborderright">

            <table width="100%" height="100%">
              <tbody>
                {mSpeedDialFolded ? <tr/> :
                  <tr height="0">
                    <td className="padded10" align="left" valign="top">
                      
                      <div style={{visibility: (mSpeedDialFolded ? "hidden" : "visible")}}>
                        {mSpeedDials.map((speeddial,index) => (
                          <div className="contactlistitem"><ContactSpeedDial key={index} Number={speeddial.Number} Name={speeddial.Name} Application={speeddial.Application} ScriptName={speeddial.ScriptName} ScreenpopUrl={speeddial.ScreenpopUrl} MakeCallEvent={(number, name, application, scriptName, screenpopUrl) => MakeCall(number, name, application, scriptName, screenpopUrl)} RemoveFromSpeedDialEvent={() => RemoveFromSpeedDialByIndex(index)}/></div>
                        ))} 
                      </div>

                    </td>
                  </tr>}
                <tr height="10"><td className="mainborderleft mainborderright"/></tr>
                <tr height="24">
                  <td align="left" valign="top" className="padded10 mainborderbottom">
                    {HtmlHistoryHeader()}
                  </td>
                </tr>
                {mCallHistoryFolded ? <tr height="*"/> :
                  <tr height="*">
                    <td align="left" valign="top" className="padded10">

                      <div className="historymain" style={{visibility: (mCallHistoryFolded ? "hidden" : "visible")}}>
                        {CallHistory.map((call,index) => (
                          <div className="contactlistitem"><ContactCall key={index} Id={call.Id} Number={call.Number} Name={call.Name} Application={call.Application} Scripts={call.Scripts} Direction={call.Incoming ? "Incoming" : "Outgoing"} Time={MakeOsLocaleInfoShortDate(call.DateTime)} Duration={call.Duration} AlreadySpeedDial={NumberInSpeedDial(call.Number)} TeamsTheme={props.Teams.theme} VisualMode={mVisualMode} OnMakeCall={(number, name, application, scriptName, screenpopUrl) => MakeCall(number, name, application, scriptName, screenpopUrl)} OnAddToSpeedDial={(number, name, application, scriptName, screenpopUrl) => AddToSpeedDial(number, name, application, scriptName, screenpopUrl)} OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDialByNumber(number)} OnDeleteFromCallList={(id) => DeleteFromCallList(id)}/></div>
                        ))} 
                      </div>

                    </td>
                  </tr>}
              </tbody>
            </table>

          </td>
      </tr>
      <tr height="22">
          <td colSpan="3" className="bottomerror" align="right" valign="center">{mLastError}</td>
      </tr>
      </tbody>
      </table>  
  }

  const ReturnMobileMode = () => {

    return <table className="main">        
      <tbody>
      <tr height="24">
          <td width="33%" className={mMobileModeWindow === "Search" ? "mobilemodewindowselected" : "mobilemodewindownotselected"} onClick={() => MobileModeWindowChange("Search")}><span className="up3px">{Translate("Search").toUpperCase()}</span></td>
          <td width="34%" className={mMobileModeWindow === "History" ? "mobilemodewindowselected" : "mobilemodewindownotselected"} onClick={() => MobileModeWindowChange("History")}><span className="up3px">{Translate("History").toUpperCase()}</span></td>
          <td width="33%" className={mMobileModeWindow === "Speed dial" ? "mobilemodewindowselected" : "mobilemodewindownotselected"} onClick={() => MobileModeWindowChange("Speed dial")}><span className="up3px">{Translate("Speed dial").toUpperCase()}</span></td>
      </tr>
      <tr height="*">

          {mMobileModeWindow === "Search" ? 

              <td colSpan="3" className="padded10 contactlistitem" valign="top">
                  <table height="100%" width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                      <tr><td><SearchField OnChanged={() => SearchFieldChanged()} OnSearchName={(searchfield) => SearchName(searchfield)} /></td></tr>
                      {HtmlSearchResultsRow()}
                      {HtmlSignoutAndLinksRow()}
                    </tbody>
                  </table>
              </td>

              : (mMobileModeWindow === "History" ? 

              <td colSpan="3" align="left" valign="top" className="padded10">
                <div className="historymain" style={{visibility: "visible"}}>
                  {CallHistory.map((call,index) => (
                    <div className="contactlistitem"><ContactCall key={index} Id={call.Id} Number={call.Number} Name={call.Name} Application={call.Application} Scripts={call.Scripts} Direction={call.Incoming ? "Incoming" : "Outgoing"} Time={MakeOsLocaleInfoShortDate(call.DateTime)} Duration={call.Duration} AlreadySpeedDial={NumberInSpeedDial(call.Number)} TeamsTheme={props.Teams.theme} VisualMode={mVisualMode} OnMakeCall={(number, name, application, scriptName, screenpopUrl) => MakeCall(number, name, application, scriptName, screenpopUrl)} OnAddToSpeedDial={(number, name, application, scriptName, screenpopUrl) => AddToSpeedDial(number, name, application, scriptName, screenpopUrl)} OnRemoveFromSpeedDial={(number) => RemoveFromSpeedDialByNumber(number)} OnDeleteFromCallList={(id) => DeleteFromCallList(id)}/></div>
                  ))} 
                </div>
              </td>

              :

              <td colSpan="3" className="padded10" align="left" valign="top">                      
                <div style={{visibility: "visible"}}>
                  {mSpeedDials.map((speeddial,index) => (
                    <div className="contactlistitem"><ContactSpeedDial key={index} Number={speeddial.Number} Name={speeddial.Name} Application={speeddial.Application} ScriptName={speeddial.ScriptName} ScreenpopUrl={speeddial.ScreenpopUrl} MakeCallEvent={(number, name, application, scriptName, screenpopUrl) => MakeCall(number, name, application, scriptName, screenpopUrl)} RemoveFromSpeedDialEvent={() => RemoveFromSpeedDialByIndex(index)}/></div>
                  ))} 
                </div>
              </td>)
            }
      </tr>
      <tr height="22">
          <td colSpan="3" className="bottomerror" align="right" valign="center">{mLastError}</td>
      </tr>
      </tbody>
      </table>
  }

  return ( 
    mVisualMode == "MobileMode" ? ReturnMobileMode() :
      (mVisualMode == "CompactMode" ? ReturnCompactMode() :
        (mVisualMode == "SemiFullMode" ? ReturnSemiFullMode() : ReturnFullMode()))
  );
}

Main.propTypes = {
  Teams: PropTypes.object,
  MicrosoftToken: PropTypes.string,
  SecurityToken: PropTypes.string,
  PhoneType: PropTypes.string,
  UserIsAdmin: PropTypes.bool,
  TenantGuid: PropTypes.string,
  Beta: PropTypes.bool
}

export default Main;